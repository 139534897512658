<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="点击搜索项目"
            @click="search"
            readonly
            ref="keyword"
          ></van-search>
        </van-col>
      </van-row>
    </div>
    <van-swipe class="banner" :autoplay="6000" indicator-color="white">
      <van-swipe-item>
        <img
          class="img"
          :src="require('../../../../assets/images/labor/ban01.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="classify">
      <van-grid :column-num="4">
        <van-grid-item
          :text="classify.name"
          v-for="classify in classifys"
          :key="classify.code"
          @click="classifySearch(classify.param)"
        >
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="classify.icon"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 热门项目</van-col
        >
      </van-row>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        class="products"
        :immediate-check="false"
        @load="retrieveHotProductPage"
      >
        <template #default>
          <van-row v-for="product in productList" :key="product.code">
            <van-col @click="detail(product.code)" span="24" class="product">
              <van-row class="bbd">
                <van-col span="7">
                  <div>
                    <van-image
                      radius="3"
                      class="img"
                      v-if="product.code === '1'"
                      :src="
                        require('../../../../assets/images/labor/china.jpg')
                      "
                    ></van-image>
                    <van-image
                      radius="3"
                      class="img"
                      v-if="product.code === '2'"
                      :src="
                        require('../../../../assets/images/labor/singapore.jpg')
                      "
                    ></van-image>
                    <van-image
                      radius="3"
                      class="img"
                      v-if="product.code === '3'"
                      :src="require('../../../../assets/images/labor/rb.jpg')"
                    ></van-image>
                    <van-image
                      radius="3"
                      class="img"
                      v-if="product.code === '4'"
                      :src="require('../../../../assets/images/labor/adly.jpg')"
                    ></van-image>
                    <van-image
                      radius="3"
                      class="img"
                      v-if="product.code === '5'"
                      :src="
                        require('../../../../assets/images/labor/208_nzl.png')
                      "
                    ></van-image></div
                ></van-col>
                <van-col span="17">
                  <van-row>
                    <van-col span="24" class="name bbd">{{
                      product.name
                    }}</van-col>
                  </van-row>
                  <van-row>
                    <van-col span="24" class="desc">{{
                      product.description
                    }}</van-col>
                  </van-row>
                </van-col>
              </van-row>
              <van-row class="bbd">
                <van-col span="5"
                  ><van-tag :color="COLOR_M" class="label"
                    >福利待遇</van-tag
                  ></van-col
                >
                <van-col span="19">
                  <div
                    v-for="label in product.labels"
                    :key="label.code"
                    class="labels"
                  >
                    <van-tag class="label" v-if="label.type === 'WFE'">{{
                      label.name
                    }}</van-tag>
                  </div>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="5"
                  ><van-tag :color="COLOR_M" class="label"
                    >工作种类</van-tag
                  ></van-col
                >
                <van-col span="19"
                  ><div
                    v-for="label in product.labels"
                    :key="label.code"
                    class="labels"
                  >
                    <van-tag class="label" v-if="label.type === 'JOB'">{{
                      label.name
                    }}</van-tag>
                  </div></van-col
                >
              </van-row>
            </van-col>
          </van-row>
          <van-row v-if="productList.length <= 0">
            <van-col span="24" class="no-record">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image>
            </van-col>
          </van-row>
        </template>
      </van-list>
    </div>
    <Clue ref="clue" />
    <Call
      ref="call"
      win-show="false"
      entry-show="true"
      clue-model="LABOR_CONSULT_ONLINE"
    />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="CNT" module="CNT_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
